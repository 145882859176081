/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {


  function disableScrollify() {

      if ($(window).width() >= 992) {

        $.scrollify.enable();

      } else {

        $.scrollify.disable();

      }

  }

  function centerObjects() {

    var blocksHeight = $(window).outerHeight();
    $('.galleryCarousel').css('height', blocksHeight);
    $('.galleryCarousel .gallerySlide').css('height', blocksHeight);
    $('.galleryCarousel .gallerySlideInner').css('height', blocksHeight);

    var centerBlock = $(window).outerHeight();
    var blockHeightHome = $(".contentHome").outerHeight();
    var blockHeightEvents = $(".addHeight").outerHeight();
    var blockHeightContact = $(".contactAndForm").outerHeight();
    var blockHeightContactMobile = $(".contactAndFormMobile").outerHeight();
    var blockHeightMenu = $('.menuContent').outerHeight();

    $('.contentHome').css('padding-top', centerBlock / 2  - blockHeightHome / 3);

    $('.contentEvents .addHeight').css('padding-top', centerBlock / 2  - blockHeightEvents / 4.5);
    $('.contentEvents .addHeight').css('padding-bottom', centerBlock / 2  - blockHeightEvents / 4.5);

    $('.contentNews .addHeight').css('padding-top', centerBlock / 2  - blockHeightEvents / 6);
    $('.contentNews .addHeight').css('padding-bottom', centerBlock / 2  - blockHeightEvents / 6);

    $('.contactAndForm').css('padding-top', centerBlock / 2  - blockHeightContact / 1.2);
    $('.contactAndFormMobile').css('padding-top', centerBlock / 2  - blockHeightContactMobile / 2);

    $('.menuContent').css('padding-top', centerBlock / 2 - blockHeightMenu / 4);

  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // $('.hamburger').on('click', function(){
        //
        //   $(this).toggleClass('is-active');
        //   $('.menuDrop').toggleClass('addHeight');
        //
        // });





        //disableScrollify();

        centerObjects();

        $(window).resize(function(){

          centerObjects();

        });


        $('a','.navClick').on('click', function (){

            $.scrollify.move($(this).attr('href'));
            return false;

        });

        jQuery('.owl-carousel').owlCarousel({
          items: 1,
          center: false,
          loop: true,
          nav: true,
          dots:true,
          autoplay: false,
          navText: ["<img src='/wp-content/themes/forts-of-india/assets/images/previous-arrow.png'>","<img src='/wp-content/themes/forts-of-india/assets/images/next-arrow.png'>"],
        });

        jQuery('.galleryCarousel').owlCarousel({

          items: 1,
          center: false,
          loop: true,
          nav: true,
          dots:true,
          autoplay: true,
          navText: ["<img src='/wp-content/themes/forts-of-india/assets/images/prev-arrow.jpg'>","<img src='/wp-content/themes/forts-of-india/assets/images/next-arrow.jpg'>"]

        });

        jQuery('.aboutUsCarousel').owlCarousel({

          items: 1,
          center: false,
          loop: true,
          nav: false,
          dots:true,
          autoplay: true,
          autoplayTimeout:12000,
          navText: ["<img src='/wp-content/themes/forts-of-india/assets/images/white-prev-arrow.png'>","<img src='/wp-content/themes/forts-of-india/assets/images/white-next-arrow.png'>"]

        });

        if(window.location.hash){
          $('html,body').animate({
            scrollTop: $(window.location.hash).offset().top - $('.navHeader').height()
          })
        }
        $('a').on('click',function(){
          if($(this).attr('href').length){
            $('html,body').animate({
              scrollTop: $($(this).attr('href')).offset().top - $('.navHeader').height()
            })
          }
          return false;
        });

        $('.contactUsTab').on('click', function(){

          $('.contactUsTab').addClass('active');
          $('.enquiryForm').removeClass('active');
          $('.contactForm').addClass('hidden');
          $('.foodHygiene').removeClass('hidden');
          $('.contactUs').removeClass('hidden');


        });

        $('.enquiryForm').on('click', function(){

          $('.contactUsTab').removeClass('active');
          $('.enquiryForm').addClass('active');
          $('.contactUs').addClass('hidden');
          $('.foodHygiene').addClass('hidden');
          $('.contactForm').removeClass('hidden');

        });


        // if($(window).width() >= 768 && $(window).width() <= 1024) {
        //
        //   function doOnOrientationChangeSecond()
        //   {
        //     switch(window.orientation)
        //     {
        //       case -90:
        //       case 90:
        //         //alert('landscape');
        //
        //         $('body').css('display','block');
        //         break;
        //       default:
        //         //alert('portrait');
        //         $('body').css('display','none');
        //         break;
        //     }
        //   }
        //
        //   window.addEventListener('orientationchange', doOnOrientationChangeSecond);
        //   doOnOrientationChangeSecond();
        //
        // } else {
        //
        //   function doOnOrientationChange()
        //   {
        //     switch(window.orientation)
        //     {
        //       case -90:
        //       case 90:
        //         //alert('landscape');
        //
        //         $('body').css('display','none');
        //         break;
        //       default:
        //         //alert('portrait');
        //         $('body').css('display','block');
        //         break;
        //     }
        //   }
        //
        //
        //   window.addEventListener('orientationchange', doOnOrientationChange);
        //   doOnOrientationChange();
        //
        //
        // }

          $clickCount = 0;
          var downloadHeight = $(window).height();

          $('.headStripInner').on('click', function() {

              $clickCount++;

              if ($clickCount == 1) {

                $('.headStripDownload').addClass('headStripDownloadActive');

                $('.closeMenu').removeClass('hidden');

                $('.downloadMenu').addClass('hidden');

                $('.headStripDownloadActive').css('height', downloadHeight - 136);

                $('.menus').css('padding-top', downloadHeight / 2 - 191);

              } if ($clickCount == 2) {

                  $clickCount = 0;

                  $('.downloadMenu').removeClass('hidden');

                  $('.closeMenu').addClass('hidden');

                  $('.headStripDownloadActive').css('height', '0px');

                  $('headStripDownload').removeClass('headStripDownloadActive');

              }



          });

          $('.downloadSection').on('click', function() {

              $clickCount++;

              if ($clickCount == 1) {

                  $('.headStripDownload').addClass('headStripDownloadActive');

                  $('.closeMenu').removeClass('hidden');

                  $('.downloadMenu').addClass('hidden');

                  $('.headStripDownloadActive').css('height', downloadHeight - 90);

                  $('.menus').css('padding-top', downloadHeight / 8);

              } if ($clickCount == 2) {

                  $clickCount = 0;

                  $('.donwloadMenu').removeClass('hidden');

                  $('.closeMenu').addClass('hidden');

                  $('.headStripDownloadActive').css('height', '0px');

                  $('.downloadSection').removeClass('headStripDownloadActive');

              }



          });

          window.onload = function() {
            document.getElementById("my_audio").play();
          };

          $(window).scroll(function(){

            if($('.contactMenu').hasClass('active')) {

              $('.headStripInner').css('display' , 'none');

            } else {

              $('.headStripInner').css('display' , 'block');

            }


          });



      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
    $(document).ready(UTIL.loadEvents);











})(jQuery); // Fully reference jQuery after this point.
